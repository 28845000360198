import React, { useState } from 'react';
import { Navbar, Footer } from '../../components';
import { useNavigate } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase.config';

const AdminLogin = () => {
  const navigate = useNavigate();
  const [passcode, setPasscode] = useState('');
  const adminEmail = 'elitefinancialloangroup.za@gmail.com';

  const adminLoginHandler = async (e) => {
    e.preventDefault();
    if (passcode.trim().length > 0) {
      cogoToast.loading('Authenticating...');
      try {
        await signInWithEmailAndPassword(auth, adminEmail, passcode);
        navigate('/admin');
      } catch (error) {
        cogoToast.error('Login error. Try again later');
      }
    } else {
      cogoToast.error('Enter a valid passcode');
    }
  };

  return (
    <>
      <Navbar />
      <div className='w-screen bg-gray-100 pt-32 pb-24 px-4 md:px-24 lg:px-32 flex items-center justify-center flex-col'>
        <div className='px-4 py-8 bg-white rounded-md mx-auto w-full max-w-[500px]'>
          <p className='text-center font-semibold text-lg'>Admin Login</p>

          <form
            action='
          '
          >
            <input
              type='text'
              required
              placeholder='Admin Passcode'
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
              className='p-4 w-full outline-0 border-2 border-blue-500 my-4 rounded-md'
            />
            <button
              type='submit'
              className='p-4 w-full outline-0 border-0 bg-blue-500 my-2 rounded-md hover:bg-blue-600 duration-500 text-white'
              onClick={adminLoginHandler}
            >
              Login
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminLogin;
