import { signOut } from 'firebase/auth';
import { collection, onSnapshot } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../../firebase.config';
import { RiArrowRightSLine } from 'react-icons/ri';

const Admin = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [fetchingUsers, setFetchingUsers] = useState(true);

  useEffect(() => {
    const usersRef = collection(db, 'users');
    onSnapshot(usersRef, (snap) => {
      setUsers(snap.docs);
      setFetchingUsers(false);
    });
  }, []);

  const logout = () => {
    signOut(auth);

    navigate('/');
    window.location.reload();
  };

  return (
    <div className='w-full bg-gray-800 text-gray-50 pt-16 pb-16 px-4 md:px-24 lg:px-32 min-h-screen'>
      <h1 className='mb-8 text-xl font-medium'>Welcome, Admin</h1>

      <div className='min-h-[75vh] w-full mb-8'>
        {fetchingUsers ? (
          <h2 className='text-center p-8 text-xl font-medium'>
            Scanning for users...
          </h2>
        ) : (
          <>
            {users ? (
              <div>
                {users
                  .sort(
                    (a, b) =>
                      b.data().timestamp.seconds - a.data().timestamp.seconds
                  )
                  .map((user, index) => (
                    <Link
                      key={user.id}
                      to={`/admin/${user.id}`}
                      className='p-4 flex bg-gray-700 w-full mb-2 rounded-lg items-center justify-between'
                    >
                      <span>
                        {index + 1} - {user.data().fullName}
                      </span>
                      <div className='flex items-center gap-2'>
                        <p className='text-sm'>
                          <span>
                            {new Date(
                              user.data().timestamp.seconds * 1000
                            ).getDate()}
                          </span>
                          -
                          <span>
                            {new Date(
                              user.data().timestamp.seconds * 1000
                            ).getUTCMonth() + 1}
                          </span>
                          -
                          <span>
                            {new Date(
                              user.data().timestamp.seconds * 1000
                            ).getUTCFullYear()}
                          </span>
                        </p>
                        <RiArrowRightSLine className='text-xl' />
                      </div>
                    </Link>
                  ))}
              </div>
            ) : (
              <h2 className='text-center p-8 text-2xl font-bold '>No users</h2>
            )}
          </>
        )}
      </div>

      <button
        type='button'
        className='p-3 w-full text-white border-2 border-red-600 rounded-full hover:bg-red-600 duration-500'
        onClick={logout}
      >
        Logout
      </button>
    </div>
  );
};

export default Admin;
