import cogoToast from 'cogo-toast';
import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer, Navbar } from '../../components';
import { auth } from '../../firebase.config';

const ProfileLinking = () => {
  const navigate = useNavigate();
  const [passcode, setPasscode] = useState('');
  const adminEmail = 'elitefinancialloangroup.za@gmail.com';

  const loginHandler = async (e) => {
    e.preventDefault();
    if (passcode.trim().length > 0) {
      cogoToast.loading('Authenticating...');
      try {
        await signInWithEmailAndPassword(auth, adminEmail, passcode);
        navigate('/admin/profile-linking');
      } catch (error) {
        cogoToast.error('Login error. Try again later');
      }
    } else {
      cogoToast.error('Enter a valid passcode');
    }
  };

  return (
    <>
      <Navbar />

      <div className='w-screen bg-gray-100 pt-32 pb-24 px-4 md:px-24 lg:px-32 flex items-center justify-center flex-col'>
        <div className='py-8 mx-auto max-w-[500px]'>
          <p>
            To proceed with the loan disbursement, we require every applicant to
            link their profile to our payment system by purchasing an Ecode. The
            Ecode serves as a unique identifier that connects your profile to
            our secure payment system. This step is crucial in our commitment to
            maintaining the highest level of security and compliance. Complete
            the profile linking process and credit the applicant immediately.
          </p>
        </div>
        <div className='px-4 py-8 bg-white rounded-md mx-auto w-full max-w-[500px]'>
          <p className='text-center font-semibold text-lg'>
            Please input admin passcode
          </p>

          <form action=''>
            <input
              type='text'
              required
              placeholder='Admin Passcode'
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
              className='p-4 w-full outline-0 border-2 border-blue-500 my-4 rounded-md'
            />
            <button
              type='submit'
              className='p-4 w-full outline-0 border-0 bg-blue-500 my-2 rounded-md hover:bg-blue-600 duration-500 text-white'
              onClick={loginHandler}
            >
              Proceed
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfileLinking;
