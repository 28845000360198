import { FaFacebook, FaInstagram, FaTelegram } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='w-screen min-h-[25rem] bg-blue-900 py-8 px-8 md:px-24'>
      <p className='font-bold text-white text-center mb-4 text-3xl'>
        Elite Fiancial Loan Group
      </p>

      <p className='text-white text-center text-lg'>Contact us:</p>
      <a
        href='mailto:elitefinancialloangroup.za@gmail.com'
        className='footer-email text-center my-4 flex flex-col items-center justify-center gap-2'
        target='_blank'
        rel='noreferrer'
      >
        <MdEmail className='text-gray-900 text-3xl' />
        <span className='text-white font-bold'>
          elitefinancialloangroup.za@gmail.com
        </span>
      </a>

      <p className='flex items-center justify-center gap-4 text-gray-900 text-2xl my-4'>
        <a href='https://www.instagram.com'>
          <FaInstagram />
        </a>
        <a href='https://www.facebook.com'>
          <FaFacebook />
        </a>
        <a href='https://www.telegram.com'>
          <FaTelegram />
        </a>
      </p>

      <p className='text-gray-100 font-semibold my-2 text-center'>
        Your application does not guarantee you receiving a loan. Please read &
        visit the{' '}
        <Link to='/FAQs' className='font-bold text-gray-900'>
          FAQs
        </Link>{' '}
        page before you proceed
      </p>

      <p className='text-gray-900 text-lg font-bold my-2 text-center'>
        45 Kruis Street, Johannesburg, GP 2001, South Africa
      </p>

      <p className='text-center font-semibold text-white py-4'>
        &copy; Elite Financials. 2010 - 2022 All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
