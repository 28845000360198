import { v4 as uuidv4 } from 'uuid';
import auroraCorals from '../assets/jpgs/users/aurora-corals.jpg';
import harryHackerman from '../assets/jpgs/users/harry-hackerman.jpg';
import johnnyHomer from '../assets/jpgs/users/johnny-homer.jpg';
import marthaHorst from '../assets/jpgs/users/martha-horst.jpg';
import pabloSephus from '../assets/jpgs/users/pablo-sephus.jpg';
import samanthaHiggs from '../assets/jpgs/users/samantha-higgs.jpg';

const USER_DATA = [
  {
    ID: uuidv4(),
    image: harryHackerman,
    name: 'Henry Backerta',
    testimony:
      'The support team provided guidance and strategic advice on the risks in applying with fake firms. I was guided all through. I only got half of the money I applied for because I was not qualified.',
  },
  {
    ID: uuidv4(),
    image: johnnyHomer,
    name: 'Jonathan Hhoma',
    testimony:
      'I got over $15K with only 8% interest when i first applied, then $25K with 12% interest.',
  },

  {
    ID: uuidv4(),
    image: auroraCorals,
    name: 'Aury Coralei',
    testimony:
      'Application is very easy and the agents are patient. I searched for a platform like this for a very long time. The upfront fee was well worth it.',
  },
  {
    ID: uuidv4(),
    image: samanthaHiggs,
    name: 'Sam Higgie',
    testimony:
      'Its so rewarding working with Elite Group. It is very reliable and helped my small business to grow.',
  },
  {
    ID: uuidv4(),
    image: marthaHorst,
    name: 'Martha Sii Man',
    testimony:
      "Easy to apply, legit platform to collect loans with little interest. It is not a 'Get 25 Million Dollars in 2 days' platform.",
  },
  {
    ID: uuidv4(),
    image: pabloSephus,
    name: 'Andy Sokoni',
    testimony:
      'The agents walked me through the whole application process, I got approved and paid after 4 rejected applications but at the end I got it.',
  },
];

export default USER_DATA;
