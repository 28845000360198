import React from 'react';
import { HiCheckCircle } from 'react-icons/hi';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const Success = () => {
  return (
    <div className='flex flex-col items-center justify-center min-h-screen p-4 w-full max-w-[700px] mx-auto'>
      <HiCheckCircle className='text-blue-500 text-9xl' />
      <p className='uppercase text-xl font-bold text-center mb-4'>
        Application Successful
      </p>
      <div className='text-center w-full'>
        <p className='mb-2 text-lg'>
          We are done with this stage now we will move to the next stage.
        </p>
        <p className='mb-2 text-lg'>
          Your loan would be paid out in the desired date if approved.
        </p>
        <p className='mb-8 text-lg'>
          Contact the agent handling your application to submit other required
          documents.
        </p>

        <Link
          to='/dashboard'
          className='flex items-center justify-center gap-4 py-4 px-8 rounded-lg text-blue-500 mt-8 font-semibold'
        >
          <span>Go back home</span>
          <RiArrowRightSLine className='text-xl' />
        </Link>
      </div>
    </div>
  );
};

export default Success;
