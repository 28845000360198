import TransactionHistory from '../../../components/Dashboard/TransactionHistory';
import LoadingScreen from '../../../components/Spinner';
// import Meta from '@/components/Global/Meta';
import { auth, db } from '../../../firebase.config';
// import { useDataContext } from '@/context/AuthContext';
import { deleteUser, getAuth } from 'firebase/auth';
import {
  DocumentData,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import cogoToast from 'cogo-toast';
import React, { FormEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useOutletContext, useParams } from 'react-router-dom';
import Modal from '../../../components/Modal';
import AdminLayout from '../../../layouts/AdminLayout';
import countries from '../../../utils/countries';
import parseDate from '../../../utils/parseDate';

const UserDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const [balanceType, setBalanceType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  const [userId, setUserId] = useState('');
  const [amount, setAmount] = useState('');

  // const data = useDataContext();

  const { userData } = useOutletContext();

  const params = useParams();

  useEffect(() => {
    const _id = params.id;

    const getUserById = async () => {
      if (_id) {
        const userRef = doc(db, 'users', _id);

        setUserId(_id);

        onSnapshot(userRef, (snap) => {
          if (snap.exists()) {
            setCurrentUser(snap.data());

            setIsLoading(false);
          }
        });
      }
    };

    getUserById();
  }, [params.id]);

  const fundUserAccount = (bal) => {
    // if (data) {
    setBalanceType(bal);
    // }

    setModalOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (+amount >= 0) {
      if (currentUser) {
        const ref = doc(db, 'users', currentUser?._id);

        cogoToast.loading('Setting balance...');

        await updateDoc(ref, {
          [balanceType]: +amount,
        })
          .then(() => {
            cogoToast.success(`${balanceType} updated succesfully.`);

            setModalOpen(false);

            // setTimeout(() => {
            //   router.reload();
            // }, 1500);
          })
          .catch((err) => {
            cogoToast.error('Error');
            console.log(err);
          });
      }
    } else cogoToast.error('Amount must be 0 or more');
  };

  const deleteCurrentUser = async (id) => {
    const ref = doc(db, 'users', id);

    // if (confirm('Are you sure you want to delete this user? ')) {
    //   toast.loading('Deleting user...');

    //   await deleteDoc(ref)
    //     .then(() => {
    //       toast.dismiss();

    //       alert(
    //         'User successfully deleted from admin. For security reasons, only developer can delete user from database. Contact developer.'
    //       );

    //       // setTimeout(() => {
    //       //   router.replace('/admin/users');
    //       // }, 1500);
    //     })
    //     .catch((err) => {
    //       toast.error('Error');
    //       console.log(err);
    //     });
    // }
  };

  const verifyUserAccount = async () => {
    if (currentUser?._id) {
      const ref = doc(db, 'users', currentUser?._id);

      toast.loading('Verifying user account..');

      await updateDoc(ref, {
        isVerified: true,
      })
        .then(() => {
          toast.dismiss();

          toast.success(`Succesfully verified.`);

          // setTimeout(() => {
          //   router.reload();
          // }, 1500);
        })
        .catch((err) => {
          toast.error('Error. Cannot verify user.');
          console.log(err);
        });
    }
  };

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading && currentUser && (
        <>
          <Modal
            heading={`SET NEW ${balanceType.toUpperCase()} VALUE`}
            open={modalOpen}
            closeModal={() => setModalOpen(false)}
          >
            <form onSubmit={handleSubmit}>
              <div className='w-full col-span-1'>
                <label
                  htmlFor='amount'
                  className='mb-2 font-semibold text-sm text-gray-400'
                >
                  Input New Balance*
                </label>
                <input
                  type='number'
                  id='amount'
                  placeholder='Input New Balance'
                  className='p-4 outline-none border-none w-full rounded-md text-gray-400 bg-gray-800'
                  required
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div className='flex items-center justify-end'>
                <button
                  type='submit'
                  className='btn bg-primary mt-4 py-4 text-white hover:bg-blue-700'
                >
                  Set Balance
                </button>
              </div>
            </form>
          </Modal>

          <div className='max-w-5xl mx-auto padding py-32'>
            <p className='text-xl font-extrabold mb-8'>User Details</p>

            <div className='p-4 bg-gray-300 rounded-md my-4'>
              <div className='grid grid-cols-1 sm:grid-cols-3  lg:grid-cols-4 gap-x-4 gap-y-8 my-4 items-center'>
                {!currentUser.avatar_url && (
                  <div className='mx-auto sm:mx-0 w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center font-bold text-4xl'>
                    {currentUser?.fullname?.split(' ') &&
                    currentUser.fullname.split(' ').length > 1 ? (
                      <>
                        {currentUser?.fullname?.split(' ')[0][0]}
                        {currentUser?.fullname?.split(' ')[1][0]}
                      </>
                    ) : (
                      <>{currentUser?.fullname?.split(' ')[0][0]}</>
                    )}
                  </div>
                )}
                {currentUser.avatar_url && (
                  <img
                    src={currentUser.avatar_url}
                    className='mx-auto sm:mx-0 w-32 h-32 rounded-full'
                  />
                )}
                <div>
                  <p className='font-bold uppercase text-lg'>
                    {currentUser.fullname}
                  </p>
                  <p className='text-gray-600 text-sm'>{currentUser.email}</p>
                </div>
                <div>
                  <p className='font-bold uppercase text-lg'>Password</p>
                  <p className='text-gray-600 text-sm'>
                    {currentUser.access ?? '- - -'}
                  </p>
                </div>
                <div>
                  <p className='font-bold uppercase text-lg'>Date Joined</p>
                  <p className='text-gray-600 text-sm'>
                    {parseDate(currentUser.timestamp.seconds * 1000)}
                  </p>
                </div>
              </div>
            </div>
            {/*  */}
            <div className='my-8'>
              <button
                className={`w-full btn p-4 text-white uppercase ${
                  currentUser.account_level === 1
                    ? 'bg-yellow-500'
                    : currentUser.account_level === 2
                    ? 'bg-green-500'
                    : 'bg-blue-500'
                }`}
              >
                Promote to Level {currentUser.account_level + 1}
              </button>
            </div>
            {/*  */}
            <div className='p-4 py-8 bg-gray-200 border border-gray-300 rounded-md my-4'>
              <p className='mb-8 text-gray-800'>USER DETAILS</p>
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-8 my-4'>
                <div>
                  <p className='text-gray-600 uppercase text-[12px]'>
                    full name
                  </p>
                  <p className='font-bold'>{currentUser.fullname}</p>
                </div>

                <div>
                  <p className='text-gray-600 uppercase text-[12px]'>
                    phone number
                  </p>
                  <p className='font-bold'>{currentUser.phone ?? '_ _ _'}</p>
                </div>
                <div>
                  <p className='text-gray-600 uppercase text-[12px]'>
                    username
                  </p>
                  <p className='font-bold'>{currentUser.username}</p>
                </div>
                <div>
                  <p className='text-gray-600 uppercase text-[12px]'>email</p>
                  <p className='font-bold'>{currentUser.email}</p>
                </div>
                <div>
                  <p className='text-gray-600 uppercase text-[12px]'>country</p>
                  <p className='font-bold'>{currentUser.country}</p>
                </div>
              </div>
            </div>
            {/*  */}
            <div className='p-4 py-8 bg-gray-200 border border-gray-300 rounded-md my-4'>
              <p className='mb-8 text-gray-700'>ACCOUNT DETAILS</p>
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-8 my-4'>
                <div>
                  <p className='text-gray-600 uppercase text-[12px]'>
                    Account Balance
                  </p>
                  <p className='font-bold text-2xl'>
                    {currentUser?.currency ??
                      countries
                        .find(
                          (country) =>
                            country.name.toLowerCase() ===
                            currentUser?.country?.toLowerCase()
                        )
                        .code.toUpperCase()}{' '}
                    {currentUser.balance.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className='text-gray-600 uppercase text-[12px]'>
                    Income Balance
                  </p>
                  <p className='font-bold text-2xl'>
                    {currentUser?.currency ??
                      countries
                        .find(
                          (country) =>
                            country.name.toLowerCase() ===
                            currentUser?.country?.toLowerCase()
                        )
                        .code.toUpperCase()}{' '}
                    {currentUser.income.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className='text-gray-600 uppercase text-[12px]'>
                    Savings Balance
                  </p>
                  <p className='font-bold text-2xl'>
                    {currentUser?.currency ??
                      countries
                        .find(
                          (country) =>
                            country.name.toLowerCase() ===
                            currentUser?.country?.toLowerCase()
                        )
                        .code.toUpperCase()}{' '}
                    {currentUser.savings.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className='text-gray-600 uppercase text-[12px]'>
                    Refundable Balance
                  </p>
                  <p className='font-bold text-2xl'>
                    {currentUser?.currency ??
                      countries
                        .find(
                          (country) =>
                            country.name.toLowerCase() ===
                            currentUser?.country?.toLowerCase()
                        )
                        .code.toUpperCase()}{' '}
                    {currentUser.refundable_balance.toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
            {/*  */}
            <div className='my-8 grid grid-cols-2 md:grid-cols-4 gap-4'>
              <button
                className='btn p-4 bg-primary text-white uppercase rounded-md'
                onClick={() => fundUserAccount('balance')}
              >
                Set Balance
              </button>
              <button
                className='btn p-4 bg-green-500 text-white uppercase rounded-md'
                onClick={() => fundUserAccount('income')}
              >
                Set Income
              </button>
              <button
                className='btn p-4 bg-orange-500 text-white uppercase rounded-md'
                onClick={() => fundUserAccount('savings')}
              >
                Set Savings
              </button>
              <button
                className='btn p-4 bg-gray-500 text-white uppercase rounded-md'
                onClick={() => fundUserAccount('refundable_balance')}
              >
                Set Refund. Balance
              </button>
            </div>
            {/*  */}
            <div className='text-gray-800 p-4 bg-gray-200 border border-gray-300 rounded-md my-4'>
              <p className='text-lg font-extrabold mb-8'>KYC Documents</p>
              {currentUser.kyc_documents && (
                <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
                  {currentUser.kyc_documents.map((document) => (
                    <img
                      key={document}
                      src={document}
                      alt={currentUser._id}
                      className='w-full'
                    />
                  ))}
                </div>
              )}

              {!currentUser.kyc_documents && (
                <p className='p-8 text-center'>No documents uploaded</p>
              )}

              {!currentUser.isVerified && currentUser.kyc_documents && (
                <button
                  className={`mt-8 w-full btn p-4 text-white uppercase bg-green-500`}
                  onClick={verifyUserAccount}
                >
                  Verify user account
                </button>
              )}
            </div>
            {/*  */}
            <div className='mt-16'>
              <p className='text-lg font-extrabold mb-4'>Transaction History</p>
              <TransactionHistory
                userId={userId}
                transactions={[
                  ...currentUser.deposits,
                  ...currentUser.withdrawals,
                ]}
                isAdmin={true}
              />
            </div>

            <button
              className='mt-8 w-full btn p-4 bg-red-600 text-white uppercase'
              onClick={() => deleteCurrentUser(userId)}
            >
              Delete User
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default UserDetails;
