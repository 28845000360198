import { CgMenu } from 'react-icons/cg';

export default function Navbar({ setOpen }) {
  return (
    <header className='fixed p-4 top-0 left-0 w-full z-10 bg-secondary'>
      <div
        className={`py-2 flex items-center justify-between w-full  max-w-4xl mx-auto
      `}
      >
        <button
          aria-label='Sidebar toggle Button'
          onClick={setOpen}
          className='text-white p-2 bg-primary rounded-sm'
        >
          <CgMenu className='text-xl' />
        </button>

        <p className='font-bold text-xl text-gray-50 text-center'>
          Elite Financials
        </p>
        <div></div>
      </div>
    </header>
  );
}
