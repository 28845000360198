import cogoToast from 'cogo-toast';
import React, { useState } from 'react';
import { HiMiniBanknotes } from 'react-icons/hi2';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import StatCards from '../../components/Dashboard/StatCards';
import TransactionHistory from '../../components/Dashboard/TransactionHistory';
import Modal from '../../components/Modal';
import { UserService } from '../../services/user';
import countries from '../../utils/countries';

const Withdraw = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [method, setMethod] = useState(searchParams.get('method'));
  const [asset, setAsset] = useState('income');
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { userData } = useOutletContext();

  const validateFormInputs = () => {
    return method.trim().length > 0 && asset.trim().length > 0 && +amount > 0;
  };

  const placeWithdrawal = async () => {
    if (validateFormInputs()) {
      if (!(+amount > userData[asset])) {
        const data = {
          status: 'pending',
          type: 'withdraw',
          amount: +amount,
          method,
          asset,
          date: new Date(),
        };

        setIsLoading(true);
        try {
          const res = await UserService.sendWithdrawalRequest(
            userData._id,
            data
          );

          console.log(res);

          setModalOpen(true);
          setIsLoading(false);

          cogoToast.success(
            'Withdrawal placed. Contact admin for more information'
          );

          setTimeout(() => {
            setModalOpen(false);
          }, 2000);
        } catch (error) {
          console.log(error);
          cogoToast.error('Cannot process payment at the moment');

          setIsLoading(false);
        }
      } else {
        cogoToast.error('Insufficient funds in this wallet');
      }
    } else cogoToast.error('Please provide valid details');
  };

  return (
    <div className='mx-auto max-w-4xl p-4 py-32'>
      <div
        className='w-full mb-8 p-6 pb-16 rounded-3xl border bg-primary text-gray-50 relative cursor-pointer'
        onClick={() => setModalOpen(true)}
      >
        <p className='text-2xl font-extrabold'>Request Withdrawal</p>
        <span className='absolute bottom-6 right-6 text-3xl'>
          <HiMiniBanknotes />
        </span>
      </div>
      {/*  */}
      <StatCards />
      <div className='mt-16'>
        <p className='text-xl font-bold'>Withdrawal History</p>

        <TransactionHistory
          transactions={[...userData.deposits, ...userData.withdrawals]}
        />
      </div>

      <Modal
        open={modalOpen}
        heading={'Request Withdrawal'}
        closeModal={() => {
          setModalOpen(false);
          setAsset('income');
        }}
      >
        <div className='my-6'>
          <label
            htmlFor='balanceType'
            className='font-semibold text-sm text-gray-700 mb-2'
          >
            Withdraw from:
          </label>
          <select
            name='balanceType'
            id='balanceType'
            className='w-full px-4 py-2 outline-none rounded-md bg-gray-200 text-gray-700 text-sm'
            onChange={(e) => setAsset(e.target.value.trim())}
          >
            <option value='income' defaultChecked>
              Income ({' '}
              <>
                {userData?.currency ??
                  countries
                    .find(
                      (country) =>
                        country.name.toLowerCase() ===
                        userData?.country?.toLowerCase()
                    )
                    .code.toUpperCase()}{' '}
              </>{' '}
              {userData.income.toLocaleString()})
            </option>
            <option value='savings'>
              Savings ({' '}
              <>
                {userData?.currency ??
                  countries
                    .find(
                      (country) =>
                        country.name.toLowerCase() ===
                        userData?.country?.toLowerCase()
                    )
                    .code.toUpperCase()}{' '}
              </>{' '}
              {userData.savings.toLocaleString()})
            </option>
            <option value='refundable_balance'>
              Refundable Balance ({' '}
              <>
                {userData?.currency ??
                  countries
                    .find(
                      (country) =>
                        country.name.toLowerCase() ===
                        userData?.country?.toLowerCase()
                    )
                    .code.toUpperCase()}{' '}
              </>{' '}
              {userData.refundable_balance.toLocaleString()})
            </option>
          </select>
        </div>
        <div className='my-6'>
          <label
            htmlFor='method'
            className='font-semibold text-sm text-gray-700 mb-2'
          >
            Payment Method
          </label>
          <select
            name='method'
            id='method'
            className='w-full px-4 py-2 outline-none rounded-md bg-gray-200 text-gray-700 text-sm'
            onChange={(e) => setMethod(e.target.value.toLowerCase())}
          >
            {['Bank', 'Bitcoin', 'Paypal', 'Skrill'].map((el) => (
              <option className='capitalize' key={el} value={el}>
                {el}
              </option>
            ))}
          </select>
        </div>
        {/*  */}
        <div className='my-6'>
          <label
            htmlFor='amount'
            className='font-semibold text-sm text-gray-700 mb-2'
          >
            Amount ({asset.toUpperCase()})
          </label>
          <input
            type='number'
            name='amount'
            id='amount'
            className='w-full px-4 py-2 outline-none rounded-md bg-gray-200 text-gray-700 text-sm'
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <button
          type='button'
          className={`btn text-gray-50 rounded-md py-3 px-12 ${
            isLoading
              ? 'bg-gray-600 hover:bg-gray-600'
              : 'bg-primary hover:bg-secondary'
          }`}
          onClick={placeWithdrawal}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Withdraw'}
        </button>
      </Modal>
    </div>
  );
};

export default Withdraw;
