import { useState } from 'react';
import { Footer, Navbar, Telegram, WhatsApp } from '../components';

const Contact = () => {
  const [formData, setFormData] = useState({
    receiverEmail: 'elitefinancialloangroup.za@gmail.com',
    name: '',
    purpose: '',
    message: '',
  });

  const { receiverEmail, name, purpose, message } = formData;

  const formValid =
    name.trim().length > 0 &&
    purpose.trim().length > 0 &&
    message.trim().length > 0;

  const onInputChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  return (
    <>
      <Navbar />
      <WhatsApp />
      <Telegram />
      <main className='w-screen contact-page bg-gray-900 py-32 px-8 md:px-24 lg:p-32'>
        <h1 className='relative text-2xl text-center text-white font-bold'>
          Contact Us
        </h1>
        <div className='relative w-full p-8 bg-gray-100 my-4'>
          <form>
            <div className='mb-4'>
              <label htmlFor='name' className='block font-bold mb-2'>
                Your name:{' '}
              </label>
              <input
                type='text'
                id='name'
                placeholder='My full name is..'
                value={name}
                onChange={onInputChange}
                className='outline-none p-4 w-full bg-transparent border-[2px] border-gray-500 rounded-lg'
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='purpose' className='block font-bold mb-2'>
                Your purpose of contacting us
              </label>
              <input
                type='text'
                id='purpose'
                placeholder='I am contacting for...'
                value={purpose}
                onChange={onInputChange}
                className='outline-none p-4 w-full bg-transparent border-[2px] border-gray-500 rounded-lg'
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='message' className='block font-bold mb-2'>
                Your message:{' '}
              </label>
              <textarea
                type='text'
                id='message'
                placeholder='Your message '
                value={message}
                onChange={onInputChange}
                className='outline-none p-4 w-full bg-transparent border-[2px] border-gray-500 rounded-lg resize-y'
              />
            </div>

            <a
              href={`mailto:${receiverEmail}?Subject=${`${name} via Elite Financials 👉🏽👉🏽 ${purpose}`}&body=${message}`}
            >
              <button
                type='button'
                className={` text-white rounded-lg w-full p-4  duration-300 ${
                  !formValid
                    ? 'cursor-not-allowed bg-gray-400'
                    : 'cursor-pointer bg-green-500 hover:bg-green-600'
                }`}
                disabled={!formValid}
              >
                Send Message
              </button>
            </a>
          </form>
        </div>

        <p className='mt-8 text-xl text-left text-white font-semibold'>
          Our Office: 45 Kruis Street, Johannesburg, GP 2001, South Africa
        </p>
      </main>
      <Footer />
    </>
  );
};

export default Contact;
