import { useEffect } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/admin/profile-linking');
    }, 3000);
  }, [navigate]);

  return (
    <div className='w-full bg-gray-500 p-8 h-screen'>
      <div className='shadow-xl rounded-lg bg-white mx-auto max-w-lg flex items-center flex-col justify-center h-full p-4 gap-2'>
        <div className='p-4 rounded-full h-32 w-32 bg-green-100 mb-4 flex items-center justify-center'>
          <AiOutlineCheck className='text-5xl text-green-600' />
        </div>
        <p className='text-green-600 text-xl font-bold'>SUCCESS</p>
        <p className='text-gray-800 text-center'>
          Profile linking successful. Redirecting to dashboard in 3 seconds.
        </p>
      </div>
    </div>
  );
};

export default SuccessPage;
