import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { RiArrowLeftSLine } from 'react-icons/ri';

const User = () => {
  const ID = useParams().userId;
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const docRef = doc(db, 'users', ID);
    getDoc(docRef).then((doc) => {
      console.log(doc.data());
      setUserData(doc.data());
      setLoading(false);
    });
  }, [ID]);

  return (
    <div className='w-full bg-gray-800 text-gray-50 pt-16 pb-16 px-4 md:px-24 lg:px-32 min-h-screen'>
      <Link
        to='/admin'
        className='flex items-center justify-start gap-4 text-blue-500 font-medium'
      >
        <span>Home</span>
        <RiArrowLeftSLine className='text-xl' />
      </Link>
      {loading ? (
        <h1 className='text-center font-semibold text-2xl'>
          Fetching user data...
        </h1>
      ) : (
        <div className='mt-16 p-4'>
          {userData ? (
            <div className='user-data-p'>
              <p className='mb-2 text-xl text-yellow-500'>Personal Details</p>
              <p className='flex items-center gap-2'>
                <span>Full Name:</span>
                <span>{userData.fullName}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Email:</span>
                <span>{userData.email}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Phone:</span>
                <span>{userData.phone}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Gender:</span>
                <span>{userData.gender}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Address:</span>
                <span>{userData.address}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>ID / Passport NO:</span>
                <span>{userData.idNumber}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Nationality / Country:</span>
                <span>{userData.nationality}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>User Bank:</span>
                <span>{userData.bank}</span>
              </p>

              <p className='flex items-center gap-2'>
                <span>Net Pay:</span>
                <span>{parseInt(userData.netPay).toLocaleString()}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Currency:</span>
                <span>{userData.currency}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Salary Bank:</span>
                <span>{userData.salaryBank}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Next Payday:</span>
                <span>{userData.paymentDate}</span>
              </p>
              <br />

              <p className='mt-t mb-2 text-xl text-yellow-500'>Other Details</p>
              <p className='flex items-center gap-2'>
                <span>Reason for Loan:</span>
                <span>{userData.purpose}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Occupation:</span>
                <span>{userData.occupation}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Office / Workplace:</span>
                <span>{userData.workplace}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>How Long Working:</span>
                <span>{userData.jobDuration}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Earning Interval:</span>
                <span>{userData.earningPeriod}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Nature of Job:</span>
                <span>{userData.staff}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Applied In other Company:</span>
                <span>{userData.loanCompany}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>In Loan Debt:</span>
                <span>{userData.loanDebt}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Loan Amount Applied:</span>
                <span>{parseInt(userData.loanAmount).toLocaleString()}</span>
              </p>
              <p className='flex items-center gap-2'>
                <span>Monthly deductions:</span>
                <span>{userData.monthlyDeduction}</span>
              </p>
            </div>
          ) : (
            <h1 className='text-center font-semibold text-2xl'>
              No details for this user
            </h1>
          )}
        </div>
      )}
    </div>
  );
};

export default User;
