import { useState } from 'react';
import { AiOutlineWhatsApp } from 'react-icons/ai';

const WhatsApp = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  window.addEventListener('scroll', () => setScrollPosition(window.scrollY));

  return (
    <>
      {scrollPosition > 200 && (
        <a
          href='https://wa.me/+27768629347'
          target='_blank'
          rel='noreferrer'
          className='fixed bottom-4 ml-4 md:right-4 md:bottom-20 h-14 w-14 rounded-full cursor-pointer bg-green-500 flex items-center justify-center drop-shadow-xl z-10'
        >
          <AiOutlineWhatsApp className='text-4xl text-white' />
        </a>
      )}
    </>
  );
};

export default WhatsApp;
