import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div>
      <h1 className='text-5xl text-center text-gray-800 p-8'>
        Page Does Not Exist
      </h1>
      <Link className='text-center font-bold text-lg text-blue-600'>
        Back to Home
      </Link>
    </div>
  );
};

export default NotFound;
