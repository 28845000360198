import React, { useEffect, useMemo, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import AdminLayout from '../../../layouts/AdminLayout';
import { AdminService } from '../../../services/admin';
import { FaUsers } from 'react-icons/fa';
import UsersTable from '../../../components/Admin/UsersTable';

const AdminUsers = () => {
  const [searchValue, setSearchValue] = useState('');

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const _users = await AdminService.fetchAllUsers();

        setUsers(_users);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchUsers();
  }, []);

  const filteredUsers = useMemo(() => {
    console.log(
      users.filter((user) => user.email.includes(searchValue.trim()))
    );

    return users?.filter(
      (user) =>
        user.email.includes(searchValue.trim()) ||
        // user.fullname.includes(searchValue.trim()) ||
        user.fullname.includes(searchValue.trim())
    );
  }, [users, searchValue]);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(filteredUsers);
  };

  return (
    <>
      <div className='max-w-4xl mx-auto padding py-32'>
        {isLoading && (
          <p className='text-white bg-yellow-500 p-4 my-4'>Fetching Users...</p>
        )}
        <div className='mb-8 p-8 rounded-md w-full bg-secondary min-h-[112px] col-span-2 sm:col-span-1 text-white relative border-b-4 border-b-blue-700'>
          <FaUsers className='text-3xl absolute top-14 right-8' />
          <p className='font-semibold text-3xl my-2'>
            {(!isLoading && users ? users.length : '---').toLocaleString()}
          </p>
          <p className='font-bold'>Registered Users</p>
        </div>

        <form onSubmit={handleSubmit} className='mt-8'>
          <div className='w-full mb-4 relative'>
            <span className='text-xl absolute top-4 right-4 text-gray-300 cursor-pointer'>
              <BsSearch />
            </span>
            <label htmlFor='username'>{''}</label>
            <input
              type='text'
              id='username'
              name='username'
              placeholder='Search for a user (Input email)'
              className='px-4 py-3 outline-none border border-gray-300 w-full rounded-md text-gray-800 bg-gray-200'
              required
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </form>

        {users && users?.length > 0 && <UsersTable users={filteredUsers} />}
      </div>
    </>
  );
};

export default AdminUsers;
