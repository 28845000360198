import React, { useContext, useEffect, useState } from 'react';
import { BsBank2 } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';
import { FaUsersViewfinder } from 'react-icons/fa6';
import { HiBanknotes, HiIdentification } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { AdminService } from '../../services/admin';

const StatCards = () => {
  const [users, setUsers] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const _users = await AdminService.fetchAllUsers();
        const _deposits = await AdminService.fetchAllWithdrawals();
        const _withdrawals = await AdminService.fetchAllWithdrawals();

        setUsers(_users);
        setDeposits(_deposits);
        setWithdrawals(_withdrawals);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <>
      {isLoading && (
        <p className='text-white bg-yellow-500 p-4 my-4'>Fetching Data...</p>
      )}
      <div className='grid grid-cols-2 gap-2 max-w-4xl mx-auto'>
        <Link
          to='/super-admin/users'
          className='p-4 rounded-md w-full bg-[#0e85ca] min-h-[112px] col-span-2 sm:col-span-1 text-white relative border-b-4 border-b-blue-700 hover:bg-blue-700 duration-500'
        >
          <FaUsers className='text-3xl absolute top-10 right-4' />
          <p className='font-bold'>Registered Users</p>
          <p className='font-semibold text-xl my-2'>
            {(users ? users.length : '---').toLocaleString()}
          </p>
        </Link>
        {/*  */}
        <div className='p-4 rounded-md w-full bg-[#0eca82] min-h-[112px] col-span-2 sm:col-span-1 text-white relative border-b-4 border-b-green-600'>
          <FaUsersViewfinder className='absolute top-10 right-4 text-3xl' />
          <p className='font-bold'>Verified Users</p>
          <p className='font-semibold text-xl my-2'>
            {(users
              ? users.filter((user) => user.isVerified).length
              : '---'
            ).toLocaleString()}
          </p>
        </div>
        {/*  */}
        <div className='relative p-4 rounded-md w-full bg-gray-500 min-h-[112px] col-span-2 sm:col-span-1 text-white border-b-4 border-b-gray-600'>
          <HiBanknotes className='text-3xl absolute top-10 right-4' />
          <p className='font-bold mb-4'>Pending Deposits</p>
          <p className='font-semibold text-xl my-2'>
            {deposits ? (deposits?.length).toLocaleString() : '---'}
          </p>
        </div>
        {/*  */}
        <div className='p-4 rounded-md w-full bg-[#ecba2ed8] min-h-[112px] col-span-2 sm:col-span-1 text-white relative border-b-4 border-b-yellow-600'>
          <BsBank2 className='text-3xl absolute top-10 right-4' />
          <p className='font-bold'>Withdrawal Requests</p>
          <p className='font-semibold text-xl my-2'>
            {withdrawals ? (withdrawals?.length).toLocaleString() : '---'}
          </p>
        </div>
        {/*  */}
        {/* <div className='relative p-4 rounded-md w-full bg-[#6a5479] min-h-[112px] col-span-2 text-white border-b-4 border-b-gray-600'>
          <HiIdentification className='text-3xl absolute top-10 right-4' />
          <p className='font-bold'>Pending KYC</p>
          <p className='font-semibold text-xl my-2'>{(0).toLocaleString()}</p>
        </div> */}
      </div>
    </>
  );
};

export default StatCards;
