import { BiLinkAlt } from 'react-icons/bi';
import { GiArchiveRegister, GiMoneyStack, GiTakeMyMoney } from 'react-icons/gi';
import { IoDocumentAttachSharp } from 'react-icons/io5';
import { MdSupportAgent } from 'react-icons/md';
import { TbWritingSign } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { Converter, Footer, Navbar, Telegram, WhatsApp } from '../components';
import USER_DATA from '../data/Testimonies';

const Home = () => {
  return (
    <>
      <Navbar />
      <main>
        {/* <WhatsApp />
        <Telegram /> */}
        <header className='header home-header h-screen w-screen pt-32 pb-24 text-white text-center flex flex-col items-center justify-center px-6 md:px-32 lg:px-48'>
          <h1 className='mt-16 text-4xl md:text-5xl font-bold mb-4'>
            Elite Financial Loans
          </h1>
          <p className='text-xl font-medium my-2'>
            Best soft money lending firm with no upfront fee.
          </p>
          <p className='text-xl font-medium my-2'>
            We make the process of getting a loan safe and easy. We make the
            process simple enough through our quick and secure platform.
          </p>

          <div className='h-24 flex m-auto items-center justify-between gap-4'>
            <Link
              to='/register'
              className='bg-blue-600 hover:bg-blue-700 duration-300 py-4 px-12 font-semibold'
            >
              Get Started
            </Link>
            <Link
              to='/login'
              className='bg-white hover:bg-gray-300 duration-300 py-4 px-12 font-semibold text-gray-900 '
            >
              Sign In
            </Link>
          </div>
        </header>

        {/* INVESTMENT PROCEDURES */}
        <section className='bg-gray-800 py-12 px-4 md:px-10 xl:px-32 procedures'>
          <h2 className='text-center uppercase font-bold text-blue-600 text-2xl mb-12'>
            OUR APPLICATION PROCESS
          </h2>
          <section className='grid grid-cols-1 items-center gap-4 md:grid-cols-2'>
            <div className='card'>
              <GiArchiveRegister />
              <p className='card-heading'>1 - Initial Application</p>
              <p className='card-text'>
                Easily apply by contacting our agents via WhatsApp, or
                contacting us directly via Email. You will require a few
                documents for this process.
              </p>
            </div>
            <div className='card'>
              <IoDocumentAttachSharp />
              <p className='card-heading'>2 - Application Processing</p>
              <p className='card-text'>
                Now, we verify your details such as ID, Proof of Address,
                Employment Information, Credit Score, Bank Statement and
                Previous Loan Statement if available.
              </p>
            </div>
            <div className='card'>
              <TbWritingSign />
              <p className='card-heading'>3 - Underwriting & Credit Decision</p>
              <p className='card-text'>
                After verifying you, If certain criterias don't match according
                to our set requirements, we will advice you to go for a lower
                amount with a significantly different interest rate.
              </p>
            </div>
            <div className='card'>
              <BiLinkAlt />
              <p className='card-heading'>4 - PAYMENT PROFILE LINKING</p>
              <p className='card-text'>
                As an Applicant, after verifying your application process, you
                would be required to purchase an ecode, which would be used in
                linking your profile(YOUR PAYMENT INFORMATION) to our payment
                system, to enable us instantly credit you your loan amount. This
                process is compulsory for every applicant before getting
                credited your loan amount.
              </p>
            </div>
            <div className='card'>
              <GiTakeMyMoney />
              <p className='card-heading'>5 - Loan Payouts</p>
              <p className='card-text'>
                We fun immediately after the loan documents are signed and
                process is finalised. Mortgage loans, Business loans, and Loans
                on property may require additional time for legal reasons.
              </p>
            </div>
          </section>
        </section>

        {/* WHY CHOOSE US */}
        <section className='why-choose-us bg-gray-100 py-12 px-8 md:px-10 xl:px-24'>
          <h3 className='text-blue-600 mb-8 text-center text-2xl font-bold'>
            WHY CHOOSE US?
          </h3>
          <section className='grid grid-cols-1 items-center gap-4 md:grid-cols-3'>
            <div className='card'>
              <TbWritingSign />
              <p className='card-heading'>QUICK AND FAST APPLICATION</p>
              <p className='card-text'>
                Applications are processed immediately after submission. You can
                just apply, sit back and wait for our response.
              </p>
            </div>
            <div className='card'>
              <GiMoneyStack />
              <p className='card-heading'>NO UPFRONT FEE</p>
              <p className='card-text'>
                We are available to everyone, and in every country. We make sure
                you apply without upfront fee.
              </p>
            </div>
            <div className='card'>
              <MdSupportAgent />
              <p className='card-heading'>CUSTOMER SUPPORT</p>
              <p className='card-text'>
                Our support team gives 24/7 guidance, and offer fast and
                reliable response concerning any problems you might face.
              </p>
            </div>
          </section>
        </section>

        {/* CURRENCY CONVERTER   */}
        <section className='why-choose-us bg-gray-900 py-12 px-4 md:px-10 xl:px-24'>
          <h3 className='text-blue-500 mb-8 text-center text-2xl font-bold'>
            CALCULATE AND CONVERT
          </h3>
          <p className='text-center text-medium text-gray-100'>
            Calculate the total amount you will repay. This ensures that you
            make the right choices
          </p>
          <Converter />
        </section>

        {/* INVESTMENT PROCEDURES */}
        <section className='bg-white py-12 px-4 md:px-10 xl:px-32 testimonies'>
          <h2 className='text-center uppercase font-bold text-blue-600 text-2xl mb-12'>
            USER TESTIMONIES AND REVIEWS
          </h2>
          <section className='grid grid-cols-1 items-center gap-4 md:grid-cols-2 lg:grid-cols-3'>
            {USER_DATA.map((user) => (
              <div className='card' key={user.ID}>
                <div
                  className='h-32 w-32 rounded-full'
                  style={{
                    background: `url(${user.image}) center center/cover`,
                  }}
                ></div>
                <p className='card-heading'>{user.name}</p>
                <p className='card-text'>{user.testimony}</p>
              </div>
            ))}
          </section>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Home;
