import cogoToast from 'cogo-toast';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const AdminProfileLinking = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    paymentMethod: '',
    paymentId: '',
    accountNumber: '',
    IBAN: '',
    ecode: '',
    loanAmount: '',
  });

  const {
    name,
    paymentMethod,
    paymentId,
    accountNumber,
    IBAN,
    ecode,
    loanAmount,
  } = formData;

  const formInputHandler = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const submitForm = () => {
    const formIsValid =
      name.trim().length > 0 &&
      paymentMethod.trim().length > 0 &&
      paymentId.trim().length > 0 &&
      accountNumber.trim().length > 0 &&
      IBAN.trim().length > 0 &&
      ecode.trim().length > 0 &&
      parseInt(loanAmount) > 0;

    if (formIsValid) {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
        console.log(formData);

        navigate('/admin/profile-linking/success');
      }, 1000);
    } else {
      cogoToast.warn('Please fill all fields.');
    }
  };

  return (
    <div className='w-full bg-gray-900 text-gray-50 pt-16 pb-16 px-4 md:px-24 lg:px-32 min-h-screen'>
      <div className='py-8 mx-auto max-w-lg'>
        <div className='mb-12 flex items-center justify-between'>
          <p className='uppercase font-bold'>Profile Linking</p>
          <Link to='/admin' className='text-blue-500 underline'>
            {'<'} Back to Dashboard
          </Link>
        </div>
        <form action=''>
          <input
            id='name'
            type='text'
            placeholder='Name'
            className='mb-4 p-3 w-full rounded-md bg-gray-900 border border-gray-700'
            value={name}
            onChange={formInputHandler}
          />
          <input
            id='paymentMethod'
            type='text'
            placeholder='Payment Method'
            className='mb-4 p-3 w-full rounded-md bg-gray-900 border border-gray-700'
            value={paymentMethod}
            onChange={formInputHandler}
          />
          <input
            id='paymentId'
            type='text'
            placeholder='Payment ID'
            className='mb-4 p-3 w-full rounded-md bg-gray-900 border border-gray-700'
            value={paymentId}
            onChange={formInputHandler}
          />
          <input
            id='accountNumber'
            type='text'
            placeholder='Account Number'
            className='mb-4 p-3 w-full rounded-md bg-gray-900 border border-gray-700'
            value={accountNumber}
            onChange={formInputHandler}
          />
          <input
            id='IBAN'
            type='text'
            placeholder='IBAN'
            className='mb-4 p-3 w-full rounded-md bg-gray-900 border border-gray-700'
            value={IBAN}
            onChange={formInputHandler}
          />
          <input
            id='ecode'
            type='text'
            placeholder='Ecode'
            className='mb-4 p-3 w-full rounded-md bg-gray-900 border border-gray-700'
            value={ecode}
            onChange={formInputHandler}
          />
          <input
            id='loanAmount'
            type='text'
            placeholder='Loan Amount'
            className='mb-4 p-3 w-full rounded-md bg-gray-900 border border-gray-700'
            value={loanAmount}
            onChange={formInputHandler}
          />

          <button
            type='button'
            className='p-3 w-full text-white rounded-md bg-blue-500 hover:bg-blue-600 duration-500 disabled:bg-gray-500 disabled:hover:bg-gray-500 disabled:cursor-not-allowed'
            onClick={submitForm}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminProfileLinking;
