import { useState } from 'react';
import { IoClose, IoMenu } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';
import navLogo from '../assets/svgs/NavLogo-03.svg';

const Navbar = () => {
  const location = useLocation().pathname;
  const [navOpen, setNavOpen] = useState(false);

  const toggleMobileNavbar = () => setNavOpen((prev) => !prev);

  return (
    <>
      <nav
        className={`z-20 w-screen h-20 p-4 flex items-center justify-between text-gray-900 fixed top-0 shadow-md left-0 md:px8 lg:px-16 xl:px-32 ease-in transition-all bg-gray-50`}
      >
        <Link to='/' className='block w-32 md:w-36 h-full'>
          <img
            src={navLogo}
            alt='Logo for Elite Fiancial Loan Group'
            className='h-full'
          />
        </Link>
        {/*  MAIN NAV */}
        <div
          className={`z-10 flex flex-col items-center justify-evenly absolute h-screen w-[300px] top-0 left-0 bg-gray-200 duration-500 transition-all ease-out drop-shadow-xl md:bg-transparent md:relative md:w-4/5 xl:w-2/3 md:flex-row md:h-full md:justify-between md:bg-none md:right-0 ${
            navOpen ? 'left-0 md:left-0' : 'left-[-320px] md:left-0'
          }`}
        >
          <ul className='h-2/5 flex flex-col justify-between items-center md:flex-row md:w-3/5 md:h-full md:justify-around'>
            <li
              className={`hover:text-blue-600 font-semibold duration-300 ${
                location === '/' && 'text-blue-600 font-bold'
              }`}
              onClick={toggleMobileNavbar}
            >
              <Link to='/'>Home</Link>
            </li>
            <li
              className={`hover:text-blue-600 font-semibold duration-300 ${
                location === '/about' && 'text-blue-500 font-bold'
              }`}
              onClick={toggleMobileNavbar}
            >
              <Link to='/about'>About</Link>
            </li>
            <li
              className={`hover:text-blue-600 font-semibold duration-300 ${
                location === '/FAQs' && 'text-blue-500 font-bold'
              }`}
              onClick={toggleMobileNavbar}
            >
              <Link to='/FAQs'>FAQs</Link>
            </li>
            <li
              className={`hover:text-blue-600 font-semibold duration-300 ${
                location === '/contact' && 'text-blue-500 font-bold'
              }`}
              onClick={toggleMobileNavbar}
            >
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>

          <div className='h-24 flex flex-col md:flex-row justify-between items-center md:w-52'>
            <Link
              to='/login'
              className={`hover:text-blue-600 font-semibold duration-300 ${
                location === '/login' && 'text-blue-500 font-bold'
              }`}
              onClick={toggleMobileNavbar}
            >
              Login
            </Link>
            <Link
              to='/application'
              className={`bg-blue-500 hover:bg-blue-600 duration-300 py-3 px-8 rounded-lg font-semibold`}
              onClick={toggleMobileNavbar}
            >
              Apply
            </Link>
          </div>
        </div>

        <button
          type='button'
          onClick={toggleMobileNavbar}
          className='text-4xl md:hidden transition-all duration-300'
        >
          {navOpen ? <IoClose /> : <IoMenu />}
        </button>
      </nav>
    </>
  );
};

export default Navbar;
