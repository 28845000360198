import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AdminRoute from './components/AdminRoute';
import SuperAdminLayout from './layouts/AdminLayout';
import ProtectedRoute from './layouts/PrivateRoute';
import {
  About,
  Admin,
  AdminLogin,
  Contact,
  FAQs,
  Home,
  Login,
  NotFound,
  Register,
  Success,
  User,
} from './pages';
import Signup from './pages/Signup';
import AdminProfileLinking from './pages/admin/AdminProfileLinking';
import ProfileLinking from './pages/admin/ProfileLinking';
import SuccessPage from './pages/admin/SuccessPage';
import DashboardDeposit from './pages/dashboard/Deposit';
import DashboardHome from './pages/dashboard/Home';
import DashboardLoan from './pages/dashboard/Loan';
import DashboardSettings from './pages/dashboard/Settings';
import DashboardTransactions from './pages/dashboard/Transactions';
import DashboardWithdraw from './pages/dashboard/Withdraw';
import SuperAdminHome from './pages/super/Home';
import AdminUserDetails from './pages/super/Users/UserDetails';
import SuperAdminUsers from './pages/super/Users/index';

const App = () => {
  return (
    <>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/FAQs' element={<FAQs />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/application' element={<Register />} />
          <Route path='/application/success' element={<Success />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Signup />} />
          <Route path='/admin/login' element={<AdminLogin />} />
          <Route
            path='/admin/profile-linking-login'
            element={<ProfileLinking />}
          />
          <Route path='/admin' element={<AdminRoute />}>
            <Route path='/admin' element={<Admin />} />
            <Route path='/admin/:userId' element={<User />} />
            <Route
              path='/admin/profile-linking'
              element={<AdminProfileLinking />}
            />
            <Route
              path='/admin/profile-linking/success'
              element={<SuccessPage />}
            />
          </Route>
          <Route path='/dashboard' element={<ProtectedRoute />}>
            <Route path='/dashboard' element={<DashboardHome />} />
            <Route path='/dashboard/deposit' element={<DashboardDeposit />} />
            <Route path='/dashboard/withdraw' element={<DashboardWithdraw />} />
            <Route path='/dashboard/loan' element={<DashboardLoan />} />
            <Route
              path='/dashboard/transactions'
              element={<DashboardTransactions />}
            />
            <Route path='/dashboard/settings' element={<DashboardSettings />} />
          </Route>
          <Route path='/super-admin' element={<SuperAdminLayout />}>
            <Route path='/super-admin' element={<SuperAdminHome />} />
            <Route path='/super-admin/users' element={<SuperAdminUsers />} />
            <Route
              path='/super-admin/users/:id'
              element={<AdminUserDetails />}
            />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
