import { Footer, Navbar } from '../components';

const About = () => {
  return (
    <>
      <Navbar />
      <main>
        <header className='header about-header h-[80vh] w-screen pt-28 pb-20 text-white text-center grid grid-cols-1 items-center justify-center gap-4 px-8 md:text-left md:px-12 md:grid-cols-3 md:h-[70vh] lg:px-24 lg:h-[60vh]'>
          <h1 className='text-4xl md:text-5xl font-bold'>About Us</h1>
          <div className='md:col-span-2 md:text-lg'>
            <p className='font-medium my-2 text-lg'>
              We provide quality financial consultation services, offer soft
              money loans with small interest rates, and empowerment grants.
            </p>
            <p className='font-medium my-2 text-lg'>
              We are an international business working with the goal of helping
              build small businesses and private infrastructure.
            </p>
          </div>
        </header>

        <section className='bg-gray-100 py-12 px-8 md:px-10 xl:px-24'>
          <h3 className='text-gray-900 mb-8 text-center text-3xl font-bold'>
            MORE ABOUT US
          </h3>
          <p className='font-medium my-2 text-lg'>
            We allow users apply for loans simple and quick, we keep the process
            legal as we offer to over 60 countries around the world.
          </p>
          <p className='font-medium my-2 text-lg'>
            To avoid a waste of the user's time, and also to keep the loan
            process easy, we do not approve loans to all the countries of the
            world. Read our FAQs to know more.
          </p>
        </section>

        {/* COMPANY STATS */}
        <section className='bg-gray-900 py-12 px-8 md:px-10 xl:px-24'>
          <h3 className='text-blue-600 mb-8 text-center text-3xl font-bold'>
            OPEN-BOOK STATISTICS
          </h3>
          <p className='text-center font-semibold text-xl text-white mb-4'>
            Below as the stats of our comapny which we are allowed to share with
            visitors
          </p>
          <section className='grid grid-cols-1 items-center gap-4 md:grid-cols-2 lg:grid-cols-3'>
            <div className='card'>
              <p className='card-heading text-3xl text-white'>53,607</p>
              <p className='card-text text-gray-200'>APPROVED APPLICATIONS</p>
            </div>
            <div className='card'>
              <p className='card-heading text-3xl text-white'>327</p>
              <p className='card-text text-gray-200'>REJECTED APPLICATIONS</p>
            </div>
            <div className='card'>
              <p className='card-heading text-3xl text-white'>$67,988,430</p>
              <p className='card-text text-gray-200'>TOTAL LOANS APPROVED</p>
            </div>
            <div className='card'>
              <p className='card-heading text-3xl text-white'>$1,770,500</p>
              <p className='card-text text-gray-200'>OVERALL GRANTS</p>
            </div>
            <div className='card'>
              <p className='card-heading text-3xl text-white'>$657,000</p>
              <p className='card-text text-gray-200'>LOSSES RECORDED</p>
            </div>
            <div className='card'>
              <p className='card-heading text-3xl text-white'>68 COUNTRIES</p>
              <p className='card-text text-gray-200'>
                We are available in 68 countries
              </p>
            </div>
          </section>
        </section>

        {/* OUR SUPPORT SYSTEM */}
        <section className='bg-white py-12 px-8 md:px-10 xl:px-24'>
          <h3 className='text-blue-600 mb-8 text-center text-3xl font-bold'>
            CUSTOMER SUPPORT TEAM
          </h3>
          <p className='text-xl text-center font-semibold'>
            Our customer care team is fully equipped, and ever ready to solve
            any problem you might face
          </p>
          <section className='grid grid-cols-1 items-center gap-4 md:grid-cols-2'>
            <div className='card'>
              <p className='card-heading text-3xl'>256</p>
              <p className='card-text'>CREDIT SCORE FIXES</p>
            </div>
            <div className='card'>
              <p className='card-heading text-3xl'>576</p>
              <p className='card-text'>MONEY LOSSES RESOLVED YEARLY</p>
            </div>
            <div className='card'>
              <p className='card-heading text-3xl'>72</p>
              <p className='card-text'>PERMANENT LOSSES CONFIRMED</p>
            </div>
            <div className='card'>
              <p className='card-heading text-3xl'>77</p>
              <p className='card-text'>
                LANGUAGES UNDERSTOOD BY OUR CUSTOMER SUPPORT
              </p>
            </div>
          </section>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default About;
