import { useState } from 'react';
import { FaTelegramPlane } from 'react-icons/fa';

const Telegram = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  window.addEventListener('scroll', () => setScrollPosition(window.scrollY));

  return (
    <>
      {scrollPosition > 300 && (
        <a
          href='https://t.me/xfor2022'
          target='_blank'
          rel='noreferrer'
          className='fixed bottom-4 right-4 h-14 w-14 rounded-full cursor-pointer bg-blue-500 flex items-center justify-center drop-shadow-xl z-10'
        >
          <FaTelegramPlane className='text-3xl text-white' />
        </a>
      )}
    </>
  );
};

export default Telegram;
