import { useState } from 'react';

const Converter = () => {
  const [percentageConverter, setPercentageConverter] = useState({
    amount: '',
    percentage: '',
    years: '',
  });

  const [calculatedResult, setCalculatedResult] = useState({
    result: '',
    show: false,
  });

  const { amount, percentage, years } = percentageConverter;

  const percentageConverterHandler = (e) => {
    setPercentageConverter((prev) => ({
      ...prev,
      [e.target.id]: +e.target.value,
    }));
  };

  const percentageConverterSubmit = (e) => {
    e.preventDefault();

    const INTEREST = amount * (percentage / 100) * years;
    setCalculatedResult({
      result: INTEREST.toFixed(2),
      show: true,
    });

    setTimeout(() => {
      setCalculatedResult({
        result: '',
        show: false,
      });
    }, 10000);
  };

  return (
    <section className='grid grid-cols-1 items-center justify-center gap-4 my-8'>
      <div className='converter-card'>
        <h1 className='p-3 text-lg text-center'>
          SIMPLE INTEREST RATE CONVERTER
        </h1>
        <p className='text-semibold text-white text-center mb-4'>
          If you will pay back in a few months, divide the number of months by
          12 then fill it into the YEARS field
        </p>
        <div className='px-4'>
          <form>
            <div className='mb-4'>
              <label htmlFor='amount'>Loan Amount:</label>
              <input
                type='number'
                id='amount'
                value={amount}
                placeholder={'Amount...'}
                className='w-full px-4 py-3 outline-none bg-transparent border-[1px] border-blue-500 rounded-lg'
                onChange={percentageConverterHandler}
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='percentage'>Interest Rate:</label>
              <input
                type='number'
                id='percentage'
                value={percentage}
                placeholder={'Interest rate...'}
                className='w-full px-4 py-3 outline-none bg-transparent border-[1px] border-blue-500 rounded-lg'
                onChange={percentageConverterHandler}
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='years'>Years to repay:</label>
              <input
                type='number'
                id='years'
                value={years}
                placeholder={'Years...'}
                className='w-full px-4 py-3 outline-none bg-transparent border-[1px] border-blue-500 rounded-lg'
                onChange={percentageConverterHandler}
              />
            </div>
            <button
              type='button'
              className='mb-4 px-4 py-3 text-white rounded-lg bg-blue-500 w-full hover:bg-blue-600 duration-300'
              onClick={percentageConverterSubmit}
            >
              CALCULATE
            </button>
            {calculatedResult.show && (
              <div className='mb-4'>
                <textarea
                  type='text'
                  id='calculatedResult'
                  value={
                    calculatedResult.result
                      ? `Pay total: ${
                          +amount + +calculatedResult.result
                        } at a rate of ${(
                          (+amount + +calculatedResult.result) /
                          (years * 12)
                        ).toFixed(2)} monthly`
                      : 'CALCULATED RESULT'
                  }
                  placeholder={'RESULT'}
                  className='w-full p-4 outline-none bg-gray-800 rounded-lg font-medium text-lg resize-none'
                  disabled
                />
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Converter;
