// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDo9HoxyYkxhPhjaoMb4IPwxMhDws0Ahkc',
  authDomain: 'elite-financial-services-76815.firebaseapp.com',
  projectId: 'elite-financial-services-76815',
  storageBucket: 'elite-financial-services-76815.appspot.com',
  messagingSenderId: '679282901515',
  appId: '1:679282901515:web:f24c4d764520d6dea83d0e',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage();
